<template>
  <div class="error-not-found">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="error-inner text-center">
            <div class="image">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/ramayana-web.appspot.com/o/Logo%20PR.jpg?alt=media&token=2168cd2e-f260-440f-9d87-d578e98cb865"
                alt="404"
                width="100px"
              />
            </div>
            <h1 class="heading-h3">Looks like you are lost.</h1>
            <div class="error-text">
              <p>
                It looks like nothing was found at this location. You can either
                go back to the last page or go to homepage.
              </p>
              <div class="error-button-group">
                <router-link class="btn-submit bg-red" to="/">
                  Kembali Ke Halaman Utama
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
